import React from "react";
import sIcon1 from "../../images/icons/icon_clock.svg";
import sIcon2 from "../../images/icons/icon_dart_board_2.svg";
import sIcon3 from "../../images/icons/icon_target.svg";
import { GoGoal } from "react-icons/go";
import { FaRegEye } from "react-icons/fa";

const Policy = [
  {
    title: "Our Mission",
    subTitle:
      "Our goal is to be a trusted leader in cybersecurity, providing accessible, effective solutions that empower businesses and institutions to flourish in a secure digital environment, ensuring resilience and confidence in an ever-evolving digital landscape.",
    icon: <GoGoal fontSize={40} color="#0064B4" />,
  },
  {
    title: "Our Vision",
    subTitle:
      "To be a world-renowned information technology solutions provider known for their powerful and inventive solutions. We aspire to be the leading name that protrudes up in exceptional client service in the information technology sector.",
    icon: <FaRegEye fontSize={40} color="#0064B4" />,
  },
];

const PolicySection = (props) => {
  return (
    <section className="policy_section section_space">
      <div className="container">
        <div className="col-lg-12">
          <div className="heading_block text-center">
            <h2 className="heading_text text-white">
              Our Mission and Vision
            </h2>
          </div>
        </div>
        <div className="row">
          {Policy.map((policy, pitem) => (
            <div className="col-lg-6" key={pitem}>
              <div className="iconbox_block iconbox_new_footer_block">
                <div className="iconbox_icon">{policy.icon}</div>
                <div className="iconbox_content">
                  <h3 className="iconbox_title2">{policy.title}</h3>
                  <p className="mb-0">{policy.subTitle}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PolicySection;

import React, { Fragment, useEffect, useState } from "react";
import Header from "../../components/header/Header";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import ModalVideo from "react-modal-video";
import wImg from "../../images/about/image3.png";
import PolicySection from "./Policy";
import WhyUs from "./WhyUs";
import FeaturesSection from "../../components/FeaturesSection/FeaturesSection";
import CtaSection from "../../components/CtaSection/CtaSection";
import MetaTags from "../../components/MetaTags";

const AboutUsPage = (props) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <Fragment>
      <Header />
      <main className="page_content about-page">
        <MetaTags
          title="About Us | Adri Tech Labs"
          description="At Adri Tech Labs, we deliver innovative cybersecurity solutions tailored to protect businesses from digital threats. Our expert team focuses on customized security strategies, leveraging cutting-edge technology to ensure your organization stays secure and compliant in a dynamic landscape."
          keywords="About Us, Adri Tech Labs, cybersecurity solutions, digital security, tailored security strategies, threat protection, business security, cybersecurity experts"
          canonicalUrl="https://www.adritechlabs.com/about"
        />
        <PageTitle pageTitle={"About Us"} />
        <section className="intro_about_section section_space pt-6 pb-0">
          <div className="container">
            <div className="heading_block mb-0">
              <div className="row justify-content-lg-between">
                <div className="col-lg-6">
                  <div className="image_wrap text-center gap-4">
                    <img src={wImg} alt="Adri Tech Labs team collaboration" />
                  </div>
                </div>
                <div className="col-lg-6 m-auto">
                  <div className="gap-4">
                    <p className="heading_description">
                      <b>Adri Tech Labs</b> is an innovation hub delivering
                      top-tier information security solutions, including
                      specialised training, expert consulting, and cybersecurity
                      services across sectors such as corporate businesses,
                      educational institutions, and law enforcement agencies.
                    </p>
                    <p className="heading_description">
                      Understanding the rapidly evolving cyber threat landscape,
                      we craft customised cybersecurity strategies that meet the
                      unique needs of each client. Our expert team is dedicated
                      to helping organisations proactively manage and mitigate
                      risks, ensuring seamless operations and building trust
                      through innovative solutions and superior service quality.
                    </p>
                    <p className="heading_description mb-0">
                      Our mission at Adri Tech Labs is to empower clients with
                      the essential tools and knowledge to secure their digital
                      environments. We strive to make cybersecurity simple,
                      reliable, and effective, offering peace of mind so
                      businesses and individuals can confidently face the
                      challenges of the digital world.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <FeaturesSection />
        <PolicySection />
        <WhyUs />
        <CtaSection />
      </main>
      <Footer />
      <Scrollbar />
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="7e90gBu4pas"
        onClose={() => setOpen(false)}
      />
    </Fragment>
  );
};
export default AboutUsPage;

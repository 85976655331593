import React from "react";
import Bg from "../../images/shapes/bg_pattern_1.svg";

const FeaturesSection = (props) => {
  return (
    <section
      className="client_logo_section section_space"
      style={{ backgroundImage: `url(${Bg})` }}
    >
      <div className="container">
        <div className="mission-vision-values">
          <div className="line"></div>
          <div lg="4" className="card mission">
            <div className="icon">
              <i className="fa fa-bullseye"></i>
            </div>
            <h3>Pioneering Solutions</h3>
            <p>
              At Adri Tech Labs, we deliver cutting-edge, custom cybersecurity
              solutions, staying ahead of industry trends to solve each client's
              unique challenges with expertise, creativity, and precision.
            </p>
          </div>
          <div className="connector">
            <div className="circle"></div>
          </div>
          <div lg="4" className="card vision">
            <div className="icon">
              <i className="fa fa-lightbulb"></i>
            </div>
            <h3>Customer-Centric</h3>
            <p>
              We prioritize clients by offering a personalized experience and
              open communication, creating tailored solutions that foster trust
              and build long-lasting partnerships.
            </p>
          </div>
          <div className="connector">
            <div className="circle"></div>
          </div>
          <div className="card values">
            <div className="icon">
              <i className="fa fa-balance-scale"></i>
            </div>
            <h3>Trust & Integrity</h3>
            <p>
              With transparency and integrity, we’re committed to delivering
              every project with accountability, quality, and clear
              communication, ensuring the best outcomes for our clients.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
